export const USER_LOGIN_SUCCESS='USER_LOGIN_SUCCESS'
export const USER_LOGIN_START='USER_LOGIN_START'
export const USER_LOGIN_FAILED='USER_LOGIN_FAILED'
export const USER_FORGOT_FILLED='USER_FORGOT_FILLED'

export const SELLER_REGISTER_CHECK='SELLER_REGISTER_CHECK'
export const SELLER_REGISTER_SUCCESS='SELLER_REGISTER_SUCCESS'
export const SELLER_REGISTER_FAILED='SELLER_REGISTER_FAILED'

export const CART_EMPTY='CART_EMPTY'
export const CART_DATA='CART_DATA'
export const CART_LIST='CART_LIST'
export const CART_CHECKOUT='CART_CHECKOUT'
export const CART_TOTAL_WORTH='CART_TOTAL_WORTH'
export const CART_COMMERCE_PROMO='CART_COMMERCE_PROMO'
export const CART_TOTAL_CHARGE='CART_TOTAL_CHARGE'
export const CART_PAYMENT_PROMO='CART_PAYMENT_PROMO'
export const CART_TOTAL_PAYMENT='CART_TOTAL_PAYMENT'

export const PAYMENT_LIST='PAYMENT_LIST'

export const INVOICE_LIST='INVOICE_LIST'

export const ORDERS_LIST='ORDERS_LIST'
